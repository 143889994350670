import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import Foot from '../components/footer'

import poly_logo from '../images/logo_poly.webp'
import step_logo from '../images/logo_step.webp'
import gigl_logo from '../images/logo_gigl.png'
import bnc_logo from '../images/logo_bnc.webp'
import imc2_logo from '../images/logo_imc2.webp'
import logo_polyhx from '../images/logo_polyhx.png'
import logo_cybereco from '../images/logo_cybereco.png'
import logo_advens from '../images/logo_advens.png'

import '../styles/sponsers.sass'

const SponsersPage = () => {
    return (
        <Layout languageSwitchLink="/en/partners">
            <SEO title="Partenaires" />
            <Banner class_suffix="deep"/>

            <div className="presentation">
                <p>
                    C'est grâce à nos partenaires que nous pouvons réaliser tous nos projets, participer aux compétitions et développer notre club, alors nous les remercions énormément !
                <br/>
                <br/>
                <b> Vous êtes intéressés à nous soutenir et contribuer au développement de notre club ? Contactez-nous avec ce <a href={'https://forms.gle/Fok7EFvNSDLVxLbG9'}>formulaire</a> ou sur <a href={'https://ca.linkedin.com/company/polycyber'}>LinkedIn</a>.</b>
                </p>

            </div>


            {/* Industrial partners */}
            <h1 className="title">Partenaires industriels</h1>
            
            <div className="sponsors-container">

                <div className="sponsors-logos">
                    <a href="https://cybereco.ca/">
                    <img src={logo_cybereco} />
                    </a>
                    <h4 className="partner">Cybereco</h4>
                    <p className="text">Fondé en 2018, Cybereco regroupe un nombre grandissant de membres, dont des chefs de file du secteur des affaires et de l’enseignement supérieur au Québec et au Canada, réunis dans le but d’accélérer le développement d’une main-d’œuvre de calibre mondial et de solutions technologiques efficaces pour une économie prospère et sécuritaire. 
                    <br/>Nous organisons chaque année une compétition CTF   avec eux !
                    </p>

                </div>

                <div className="sponsors-logos">
                    <a href="https://www.bnc.ca/">
                        <img src={bnc_logo} />
                    </a>
                    <h4 className="partner">Banque Nationale</h4>
                    <p className="text"> Partenaire depuis plusieurs années, la Banque Nationale est un des meilleurs employeurs du Canada, proposant un environnement dynamique. 
                    <br/> Et la cybersécurité dans une banque, c’est essentiel ! C’est pourquoi la BNC recrute énormément dans le domaine de la cyber allant du pentest à la cyberdéfense en passant par la gouvernance. </p>
                </div>
                
            </div>

            <div className="sponsors-container">
                <div className="sponsors-logos">
                    <a href="http://www.advens.fr/">
                        <img src={logo_advens} />
                    </a>
                    <h4 className="partner">Advens</h4>
                    <p className="text"> Advens est un leader français, indépendant et souverain en matière de Cybersécurité. Il est présent partout en France, ainsi qu’au Québec et en Europe.
                    <br/>Sa mission est de protéger 24 heures sur 24, 365 jours par an, des organisations publiques et privées. 
                    <br/>Cette mission guide et anime Advens au quotidien. Mais ce n'est pas tout. Sa performance financière lui permet de soutenir et d’accélérer des projets à impact, au travers du fond de dotation « Advens for People And Planet ».
                    <br/>Vous l’aurez compris, plus que des expert(e)s de la cyber, Advens recherche des collaborateurs et collaboratrices engagé(e)s et passionné(e)s.
                    </p>
                </div>
            </div>
            
            {/* Academic partners */}
            <h1 className="title">Partenaires académiques</h1>
            <div className="sponsors-container">

                <div className="sponsors-logos">
                    <a href="https://www.polymtl.ca/">
                        <img src={poly_logo}/>
                    </a>
                    <h4 className="partner">Polytechnique Montréal</h4>
                    <p className="text"> On ne les présente plus, Polytechnique Montréal est un de nos partenaires principaux, et on les remercie énormément ! 
                    <br/>C’est grâce à leur soutien que de nombreux de nos projets sont possibles et c’est eux qui nous rassemblent tous. 
                    </p>
                    <p className="text">Reconnue à l’international et une des plus grandes écoles d’ingénieurs du Canada, l’école propose un large choix de programmes qui permettent à nos membres de s’épanouir et en apprendre toujours plus. Véritable référence, Polytechnique offre un enseignement de haut niveau et des activités de recherche qui répondent aux grands enjeux de notre société, influençant son environnement sur le plan intellectuel, économique et social. </p>
                    <p className="text">Si la cybersécurité t’intéresse et que tu souhaites un diplôme reconnu, Polytechnique propose : 
                        <ul>
                            <li>des certificats et microprogrammes en cybersécurité</li>
                            <li>des spécialisations au baccalauréat en génie informatique</li>
                            <li>un baccalauréat par cumul en cybersécurité</li>
                            <li>des maitrises (professionnelle ou recherche) en cybersécurité</li>
                            <li>et des doctorats, si c’est la recherche qui t’intéresse.</li>
                        </ul>
                    </p>
                </div>
                
                <div className="sponsors-logos">
                    <a href="https://i-mc2.ca/">
                        <img src={imc2_logo}/>
                    </a>
                    <h4 className="partner">Institut Multidisciplinaire en Cybersécurité et Cyberrésilience</h4>
                    <p className="text"> L’Institut Multidisciplinaire en Cybersécurité et Cyberrésilience repose sur un partenariat entre Polytechnique Montréal, initiateur du projet, l’Université de Montréal et HEC Montréal. </p>
                    <p className="text">Il permet de fédérer l’ensemble des ressources du campus de Montréal pour réaliser des projets à la fois ambitieux, innovants et multidisciplinaires. Avec de nombreux professeurs et chercheurs impliqués dans la cybersécurité, ainsi que plusieurs parcours de formation dans le domaine, le Campus de Montréal représente aujourd’hui une force de frappe de premier plan au Canada.  La mise en commun des cyber expertises en technologie, facteurs humains, et gestion positionne aujourd’hui l’IMC2 comme un interlocuteur de premier plan.</p>
                </div>
            </div>

            <div className="sponsors-container">
                <div className="sponsors-logos">
                    <a href="https://www.polymtl.ca/gigl/">
                        <img src={gigl_logo}/>
                    </a>
                    <h4 className="partner">GIGL - Département de génie informatique et logiciel</h4>
                    <p className="text"> Le GIGL, c’est le département de génie informatique et génie logiciel de Polytechnique Montréal, qui gère tous les programmes en informatique et en logiciel de l’école. C’est donc eux qui sont responsables de tous les cours, spécialisations et programmes en cybersécurité. Ils nous soutiennent beaucoup, et nous sommes fiers de les représenter dans nos projets et dans les compétitions auxquelles ils nous permettent de participer. C’est aussi notre lien principal avec les instituts de recherche de Polytechnique, qui nous permettent d’agrandir notre champ des possibles.</p>
                </div>

            </div>

            {/*Autres partenaires */}
            <h2 className="title">Partenaires étudiants</h2>
            <div className="sponsors-container">

                <div className="sponsors-particuliers">
                    <a href="https://step.polymtl.ca/">
                        <img src={step_logo}/>
                    </a>
                    <h4 className="partner">Service télématique des étudiants de Polytechnique</h4>
                    <p className="text">On remercie aussi le STEP, le comité de Polytechnique qui nous aide à maintenir nos infrastructures informatiques !</p>
                </div>
                
                
                <div className="sponsors-particuliers">
                    <a href="https://polyhacks.io/">
                        <img src={logo_polyhx}/>
                    </a>
                    <h4 className="partner">PolyHx</h4>
                    <p className="text">Et enfin PolyHx, la société technique en informatique de Polytechnique Montréal dont on fait partie !</p>
                </div>
                
            </div>

             {/*
            <h3>Patenaires du 24h@CTF</h3>
            <div className="sponsors-container">
                <div className="sponsors-logos">
                    <a href="https://www.cybereco.ca/">
                        <img src={poly_logo} className="eco-logo" />
                    </a>
                </div>
                <div className="sponsors-logos">
                    <a href="https://www.desjardins.com/">
                        <img src={poly_logo} className="desj-logo" />
                    </a>
                </div>
                <div className="sponsors-logos">
                    <a href="https://www.matlem.com/">
                        <img src={poly_logo} className="maltem-logo" />
                    </a>
                </div>
                <div className="sponsors-logos">
                    <a href="https://www.yogosha.com/">
                        <img src={poly_logo} className="bnc-logo" />
                    </a>
                </div>
            </div>
            */} 
            <Foot></Foot>
        </Layout>
    )
}
export default SponsersPage
